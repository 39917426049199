@import "colors.scss";

$navigation-height: 34px;
$row-height: 100px;

.tg-navigation {
    height: $navigation-height;
    line-height: $navigation-height;

    .tg-navigation-state {
        display: inline-block;

        font-weight: bold;
        padding: 0 8px;
    }

    .tg-navigation-elements {
        display: inline-block;

        .btn-group {
            margin-left: 8px;
        }
    }
}

.tg-grid-wrap {
    position: absolute;

    left: 0;
    right: 0;
    top: $navigation-height + 2;
    bottom: 0;

    overflow: hidden;
}

.dyn-table-wrap {
    position: absolute;

    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    overflow: hidden;

    .dyn-table-head {
        position: absolute;
        top: 0;

        z-index: 1;
    }

    .dyn-table-body {
        position: absolute;

        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        overflow: auto;
        -webkit-overflow-scrolling: touch;

        padding-top: 60px; /* height of header */

        .tg-grid > div {
            height: $row-height; /* fix the height of the rows */
        }
    }
}

.tg-grid {
    display: table;
    table-layout: fixed;
    border-collapse: collapse;

    width: 100%;

    > div {
        display: table-row;

        > div {
            display: table-cell;
            overflow: hidden;

            border: 1px solid $default-border;

            vertical-align: top;
        }
    }

    > div.table-header {
        background-color: $grey;
        color: $text-color-grey;

        font-size: 18px;
        font-weight: bold;

        > div {
            padding: 4px 8px;

            > div {
                white-space: nowrap;
                overflow: hidden;
            }
        }

        > .tg-dummy {
            width: 30px;
        }

        > .tg-meta-header {
            width: 200px;
        }
    }

    > div:not(.table-header):nth-child(odd) {
        background-color: $white;
    }

    > div:not(.table-header):nth-child(even) {
        background-color: $ultra-light;
    }

    .btn-copy,
    .btn-new {
        width: 30px;

        cursor: pointer;
        text-align: center;

        height: $row-height;
    }

    .tg-meta-info {
        width: 200px;
    }
}

.tg-order-block {
    height: $row-height;

    .tg-order-picker-input {
        height: $row-height;
        > * {
            width: 100%;
        }

        > div {
            height: 100%;
            cursor: pointer;
        }
    }
}

.tg-cost-account-picker,
.tg-expense-code-picker {
    cursor: pointer;
    height: $row-height;
    select {
        width: 100%;
        background-color: transparent;
    }
}

.tg-order-picker {
    .tg-order-search {
        display: table;
        width: 100%;

        > div {
            display: table-row;
            width: 100%;

            > div {
                display: table-cell;
                vertical-align: top;
            }
        }

        .tg-order-search-bar {
            width: 100%;

            input {
                width: 100%;
            }
        }

        .tg-order-search-button {
            width: 34px;
        }
    }

    table {
        min-width: 100%;
        border-collapse: collapse;

        thead {
            background-color: $red;
            color: white;
            font-weight: bold;
            font-size: 16px;
        }

        td {
            border: 1px solid $default-border;
            vertical-align: top;
            padding: 4px;
            white-space: nowrap;
        }

        tbody {
            tr {
                cursor: pointer;
            }

            tr:nth-child(even) {
                background-color: $white;
            }

            tr:nth-child(odd) {
                background-color: $ultra-light;
            }

            tr.selected-order {
                background-color: $light;
            }
        }
    }
}

.tg-modal {
    text-align: center;

    .modal-dialog {
        display: inline-block;
        text-align: left;

        .modal-content {
            display: inline-block;
        }
    }
}

.tg-time-record-edit,
.tg-expense-record-edit {
    input,
    textarea {
        background-color: transparent;
        width: 100%;
    }

    textarea {
        resize: none;
        border: none;
    }

    .tg-time-record-input,
    .tg-expense-record-input {
        > .tg-button {
            float: right;
            > .btn {
                border: 1px solid $default-border;
                border-width: 1px 1px 1px 0;
                background-color: transparent;
            }
        }

        > .tg-input {
            overflow: hidden;
            height: 34px;

            > input {
                height: 100%;
            }
        }
    }
}

.tg-button-block {
    display: inline-block;
    cursor: pointer;

    width: 100%;
    height: $row-height;
    line-height: $row-height;

    text-align: center;
}